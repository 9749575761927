import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Box,
  makeStyles,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import {
  formatDateToYYYYMMDD,
  TimeFormattedMinutesHours,
} from 'core/libs/core-ui/utils/dateTimeFormatted';
import palette from 'core/libs/core-ui/theme/colors-palette';
import { useTranslation } from 'core/contexts/localization';
import { formatCurrency } from 'core/libs/core-ui/utils/moneyFormatted';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

const useStyles = makeStyles((theme) => ({
  buttonCheckout: {
    backgroundColor: palette.utriper.darkgreen,
    color: '#fff',
    '&:hover': {
      backgroundColor: palette.utriper.green,
    },
  },
}));
const ExperienceDetails = ({
  info,
  dateSlot,
  quantity,
  experience,
  ...props
}) => {
  const optionsTime = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  // timeZone: 'UTC',
  }
  const { time, price, currency, priceTotal } = info || {};
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const handleCheckout = () => {
    history.push({
      pathname: '/checkout',
      state: {
        id: info.id,
        experienceDetailId: experience.expDetailId,
        groupSize: quantity,
        price: priceTotal,
      },
    });
  };

  return (
    <Box>
      <CardContent>
        <Typography variant="h5" component="h2">
          {experience.name} Details
        </Typography>
        <List>
          <ListItem>
            <EventIcon />
            <ListItemText
              primary="Date"
              secondary={DateFormattedUtils(dateSlot, 'es-Es', optionsTime)}
            />
          </ListItem>
          <ListItem>
            <PeopleIcon />
            <ListItemText
              primary="Start"
              secondary={`${TimeFormattedMinutesHours(time)}`}
            />
          </ListItem>
          <ListItem>
            <AttachMoneyIcon />
            <ListItemText
              primary="Total"
              secondary={`${currency}: ${formatCurrency(priceTotal, currency)}`}
            />
          </ListItem>
        </List>
        <Divider />
        <Button
          className={classes.buttonCheckout}
          variant="contained"
          fullWidth
          onClick={handleCheckout}>
          {translate('utils.goToCheckout')}
        </Button>
      </CardContent>
    </Box>
  );
};

export default ExperienceDetails;
