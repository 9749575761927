import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import { formatMoney } from 'core/packages/format';

const OrderSummaryPaymentText = (props) => {
  const { t: translate } = useTranslation();

  // it could be modularized in a component with a text and number
  return (
    <Grid container>
      <Grid container item>
        <Grid item xs={6} sm={6} md={6} lg={8} xl={8}>
          <h3 style={{ float: 'right', paddingRight: '2em', color: 'grey' }}>
            <strong>Total:</strong>
          </h3>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
          <h3>
            <strong>{props.currency} {formatMoney(props.total)}</strong>
          </h3>
        </Grid>
      </Grid>
      {/* <Grid container item>
        <Grid item xs={6} sm={9} md={9} lg={9} xl={9}>
          <h3 style={{ float: 'right', paddingRight: '2em', color: 'grey' }}>
            <strong>{translate('budget.checkout.second_step.pay_now')}</strong>
          </h3>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
          <h3>
            <strong>{formatMoney(props.payNow)}</strong>
          </h3>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default OrderSummaryPaymentText;
