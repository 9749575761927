import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
    },
}));

// E39348 - naranja
// A6ba62 - verde
// F2c35c - amarillo
// 9bc2d1 - celeste
export default function CircularIndeterminate({ styles = {}, centered = false, ...props }) {
    const classes = useStyles();
    const [color, setColor] = useState('#E39348');
    const colors = ['#E39348', '#A6ba62', '#F2c35c', '#9bc2d1'];

    const interval = setInterval(function () {
        // method to be executed;
        setColor(colors[Math.floor(Math.random() * 4)]);
    }, 3000);

    // clearInterval(interval);

    return (
        <Box className={centered ? classes.centered : null}>
            <div className={classes.root}>
                <CircularProgress style={{ 'color': color, ...styles }} />
            </div>
        </Box>
    );
}