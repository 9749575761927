import React, {  useState } from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import { TimeFormattedMinutesHours } from '../../../../utils/dateTimeFormatted';
import { MoneyFormattedUtils } from '../../../../utils/moneyFormatted';
import { useTranslation } from 'core/contexts/localization';
import useStyles from './styles';

const Slots = ({
    slot,
    quantity,
    daySelected,
    experience,
    setSelectedSlot,
    ...props
}) => {
    const { t: translate } = useTranslation();
    const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
    const classes = useStyles();
    
    React.useEffect(()=> {
        setSelectedSlotIndex(null)
    },[experience])

    if (!slot || !Array.isArray(slot.slots)) {
        return '';
    }
    const handleSelecteSlot = async ({ index, item }) => {
        if (!item.available) {
            return;
        }
        setSelectedSlotIndex(index);
        setSelectedSlot({
            ...item,
            priceTotal: item.price * quantity,
            timeOfArrivalFormatted: `${daySelected}T${item.time}.000Z`,
        });
    };

    return (
        <>
            <Grid container className={classes.gridFather} wrap="wrap" >
                {slot.slots.map((item, index) => (
                    <Grid
                        item
                        key={item.id || index}
                        className={classes.slotGrid}
                        xs={12}
                        md={12}
                        lg={12}>
                        <Box
                            className={classes.slotContainer}
                            m={1}
                            textAlign="center">
                            {slot.availability && (
                                <Paper
                                    key={item.id || index }
                                    data-testid={`slot-${item.id || index}`} 
                                    className={
                                        index === selectedSlotIndex
                                            ? `${classes.selectedPaper} ${classes.paperItem}`
                                            : item.available
                                            ? `${classes.availablePaper} ${classes.paperItem}`
                                            : `${classes.disabledPaper} ${classes.paperItem}`
                                    }
                                    onClick={() =>
                                        handleSelecteSlot({ index, item })
                                    }>
                                    {TimeFormattedMinutesHours(item.time)}{' '}
                                    {index !== selectedSlotIndex && item.available && item.currency}{' '}
                                    {index === selectedSlotIndex ?
                                        translate('slot.selected').toUpperCase() :
                                        (item.available
                                            ? MoneyFormattedUtils(item.price)
                                            : translate('slot.notAvailable'))}{' '}
                                    {' '} per person
                                </Paper>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default Slots;
