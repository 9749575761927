import { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';
import { translate } from 'core/locales';
import axios from 'axios';

const CurrencyInputs = () => {
  const [currency, setCurrency] = useState('$');
  const [currencyType, setCurrencyType] = useState([]);
  const handleChange = (input) => {
    setCurrency(input);
    const options = {
      method: 'GET',
      url: 'https://currency-exchange.p.rapidapi.com/exchange',
      params: { to: 'USD', from: input, q: '1.0' },
      headers: {
        'x-rapidapi-key': '6dd4a3edc8msh9f07d0593500b13p1af642jsnadd7939a04c9',
        'x-rapidapi-host': 'currency-exchange.p.rapidapi.com',
      },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(async () => {
    const options = {
      method: 'GET',
      url: 'https://currency-exchange.p.rapidapi.com/listquotes',
      headers: {
        'x-rapidapi-key': '6dd4a3edc8msh9f07d0593500b13p1af642jsnadd7939a04c9',
        'x-rapidapi-host': 'currency-exchange.p.rapidapi.com',
      },
    };

    axios
      .request(options)
      .then(function (response) {
        response.data.push('COP');
        setCurrencyType(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <>
      <FormControl variant="outlined">
        <InputLabel id="select-trip">
          {translate('layout.appBar.currency')}
        </InputLabel>
        <Select
          labelId="select-trip"
          id="select-trip"
          label={translate('layout.appBar.currency')}
          variant="outlined"
          defaultValue={currency}
          value={currency}
          onChange={(e) => handleChange(e.target.value)}>
          {currencyType.map((value) => {
            return (
              <MenuItem style={{ color: 'black' }} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const MoneyDropDown = () => {
  const handleSubmit = () => {};
  return (
    <div style={{ marginRight: '10px', minWidth: '100px' }}>
      <Form
        key={'experience-info'}
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form key={'experience-info-form'} onSubmit={handleSubmit}>
            <Field
              autoFocus
              name={'MoneyDropDown'}
              component={CurrencyInputs}
            />
          </form>
        )}
      />
    </div>
  );
};

export default MoneyDropDown;
