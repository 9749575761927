import ENVIRONMENTAL_POLICIES_JSON from "../schemas/environmental_policies";


export const ENVIRONMENTAL_POLICIES = ENVIRONMENTAL_POLICIES_JSON;

const getIdsFromNumber = (number) => {
    const binaryString = number.toString(2);
    const binaryArray = binaryString.split("");
    const binaryArrayReversed = binaryArray.reverse();
    const ids = getIds();
    const idsArray = [];
    binaryArrayReversed.forEach((bit, index) => {
        if (bit === "1") {
            idsArray.push(ids[index]);
        }
    });
    return idsArray;
};

export const getIds = () => [...Object.values(ENVIRONMENTAL_POLICIES).map(policy => policy.id)];


// autogenerate on load
const environmental_policies_copy = {}
Object.values(ENVIRONMENTAL_POLICIES).forEach(value => {
    environmental_policies_copy[value.id] = value.xlat;
});

export const environmental_policies = environmental_policies_copy;

// FIXME: maybe check that id and language are valid?
// how about merging these two functions?
export const getTranslatedName = (environmental_policies, id, language) => {
    return environmental_policies[id][language].name
};

// const getTranslatedDescription = (id, language) => this.environmental_policies[id][language].desc;

export const getTranslatedNameFromNumber = (environmental_policies, number, language) => {
    const ids = getIdsFromNumber(number);
    console.log(ids);
    const names = ids.map(id => getTranslatedName(environmental_policies, id, language));
    return names;
};