import { useState, useEffect } from 'react';
import { useTranslation } from 'core/contexts/localization';
import { usePagging } from 'core/contexts/pagging';
import { useExperiences } from 'core/contexts/experience';
import { useMediaQuery, Box, Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

// import ExperienceList from './ExperiencesList';
import Filter from './Filter';
import Header from './Header';
import Layout from 'components/Layout';
import Footer from 'components/Footer';
import Button from '@material-ui/core/Button';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import { ExperienceService } from 'core/api/services/experiences';

import DestinationCard from './DestinationCard';
// imagenes para las cards
import medellin from '../../assets/images/cities/medellin.jpg';
import sanAndres from '../../assets/images/cities/sanAndres.jpg';
import santaMarta from '../../assets/images/cities/santaMarta.jpg';
import tayrona from '../../assets/images/cities/tayrona.jpg';
import villaDeLeyva from '../../assets/images/cities/villaDeLeyva.jpg';
import laGuajira from '../../assets/images/cities/laGuajira.jpg';
import bogota from '../../assets/images/cities/bogota.jpg';
import salento from '../../assets/images/cities/salento.jpg';
// import barranquilla from '../../assets/images/cities/barranquilla.jpg'
import cartagena from '../../assets/images/cities/cartagena.jpg';
import { useFilters } from 'core/contexts/filter';
import {
    DelimitedNumericArrayParam,
    useQueryParam,
    withDefault,
    NumberParam,
    StringParam,
} from 'use-query-params';
import ExperiencesList from 'core/libs/core-ui/components/ExperienceList';
// import ExperienceList from './ExperiencesList';
import { makeStyles } from '@material-ui/core/styles';
import landingbg from 'assets/images/experiences/landingbg.jpg';
import palette from 'core/libs/core-ui/theme/colors-palette';

const IntegerArrayDefaultParam = withDefault(
    DelimitedNumericArrayParam,
    undefined
);

const useStyles = makeStyles((theme) => ({
    experiencesGrid: {
        backgroundImage: `url(${landingbg})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
    },
    header: {
        paddingTop: '80px',
        paddingBottom: '60px',
    },
    headerTitle: {
        margin: '0',
        fontSize: '1rem',
    },
    lineGreen: {
        borderBottom: '2px solid #1ebb7e', // Estilo de experiences-grid__header-line
        height: '21px',
        width: '50px',
        alignSelf: 'baseline',
    },
    headerSubtitle: {
        margin: '0',
        fontWeight: 'bold',
    },
    headerLine: {
        height: '21px',
        width: '50px',
        borderBottom: '2px solid #1ebb7e',
    },
    card: {
        justifyContent: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        padding: '1rem',
    },
    loadMoreButton: {
        backgroundColor: palette.lightblue,
        color: 'white',
        margin: '0 0.5rem',
        '&:hover': {
            backgroundColor: palette.lightblue,
        },
    },
}));

const Landing = () => {
    const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const { experiences, getExperiences } = useExperiences();
    const { currentPage, nextPage, hasMorePages } = usePagging();
    const location = useLocation();
    const [URLModus, setURLModus] = useQueryParam(
        'moods',
        IntegerArrayDefaultParam
    );

    // piece of state used to decide whether to display locations cards on landing
    const [searchParams, setSearchParams] = useState('');
    const [URLRadius, setURLRadius] = useQueryParam('radius', NumberParam);
    const [URLLat, setURLLat] = useQueryParam('lat', NumberParam);
    const [URLLng, setURLLng] = useQueryParam('lng', NumberParam);
    const [URLLocation, setURLLocation] = useQueryParam(
        'location',
        StringParam
    );

    useEffect(() => {
        setSearchParams(location.search);
        return () => setSearchParams('');
    }, [location]);

    const {
        moduses,
        locationData,
        radius,
        setModuses,
        setRadius,
        setLocationData,
    } = useFilters();

    useEffect(() => {
        const initialModuses = URLModus?.map((mood) => parseInt(mood)) ?? [];
        const initialRadius = URLRadius ?? 10;
        const initialLat = URLLat ?? 0;
        const initialLng = URLLng ?? 0;
        const initialLocation = URLLocation ?? '';

        setModuses(initialModuses);
        setRadius(initialRadius);
        setLocationData({
            name: initialLocation,
            lat: initialLat,
            lng: initialLng,
        });
    }, []);

    useEffect(() => {
        if (locationData.lng && locationData.lng != null) {
            // resetCurrentPage();
            getExperiences({ locationData, moduses, radius }, 1, true);
        }
    }, [locationData, moduses, radius]);

    // if load more is pressed, then get next page with current filters
    useEffect(() => {
        // console.log('Getting page: ' + currentPage);
        if ((locationData.name || moduses.length > 0) && currentPage > 1) {
            // console.log('Getting page: ' + currentPage);
            getExperiences(
                { locationData, moduses, radius },
                currentPage,
                false
            );
        }
    }, [currentPage]);

    return (
        <>
            <Layout showUtriperButtons={true}>
                <Header />
                <Box className={classes.experiencesGrid}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                mt={2}>
                                <Typography className={classes.headerTitle}>
                                    {translate('landing.packAndGo')}
                                </Typography>
                                <Box
                                    className={classes.lineGreen}
                                    ml={1}
                                    mr={1}></Box>
                                <Box>
                                    <FlightTakeoffIcon />
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="center" mt={1}>
                                <Typography
                                    className={classes.headerSubtitle}
                                    variant="h2">
                                    {translate('landing.awesomeTours')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Filter />
                        </Grid>
                    </Grid>

                    {searchParams === '' && (
                        <Grid container spacing={3} className={classes.card}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=6.2476376&lng=-75.56581530000001&location=Medellín%2C Medellin%2C Antioquia%2C Colombia&radius=100"
                                    name="Medellín, Medellin, Antioquia, Colombia"
                                    lat={6.2476376}
                                    lng={-75.56581530000001}
                                    imageUrl={medellin}
                                    cardText="Medellin"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=12.576855&lng=-81.705052&location=San Andrés%2C San Andres and Providencia%2C Colombia&radius=100"
                                    name="San Andrés, San Andres and Providencia, Colombia"
                                    lat={12.576855}
                                    lng={-81.705052}
                                    imageUrl={sanAndres}
                                    cardText="San Andres"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=11.2403547&lng=-74.2110227&location=Santa Marta%2C Magdalena%2C Colombia&radius=100"
                                    name="Santa Marta, Magdalena, Colombia"
                                    lat={11.2403547}
                                    lng={-74.2110227}
                                    imageUrl={santaMarta}
                                    cardText="Santa Marta"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=11.3064409&lng=-74.0657561&location=Tayrona National Park%2C Magdalena%2C Colombia&radius=100"
                                    name="Tayrona National Park, Magdalena, Colombia"
                                    lat={11.3064409}
                                    lng={-74.0657561}
                                    imageUrl={tayrona}
                                    cardText="Tayrona"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=5.636499&lng=-73.527058&location=Villa de Leyva%2C Boyaca%2C Colombia&radius=100"
                                    name="Villa de Leyva, Boyaca, Colombia"
                                    lat={5.636499}
                                    lng={-73.527058}
                                    imageUrl={villaDeLeyva}
                                    cardText="Villa de Leyva"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=11.3547743&lng=-72.5204827&location=La%20Guajira%2C%20Colombia&radius=150"
                                    name="La Guajira, Colombia"
                                    lat={11.3547743}
                                    lng={-72.5204827}
                                    imageUrl={laGuajira}
                                    cardText="La Guajira"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=4.710988599999999&lng=-74.072092&location=Bogotá%2C%20Bogota%2C%20Colombia&radius=100"
                                    name="Bogotá, Bogota, Colombia"
                                    lat={4.710988599999999}
                                    lng={-74.072092}
                                    imageUrl={bogota}
                                    cardText="Bogotá"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=4.637365&lng=-75.5703107&location=Salento%2C%20Quindio%2C%20Colombia&radius=100"
                                    name="Salento, Quindio, Colombia"
                                    lat={4.637365}
                                    lng={-75.5703107}
                                    imageUrl={salento}
                                    cardText="Salento"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <DestinationCard
                                    url="/?lat=10.3932277&lng=-75.4832311&location=Cartagena%2C%20Cartagena%20Province%2C%20Bolivar%2C%20Colombia&radius=100"
                                    name="Cartagena, Cartagena Province, Bolivar, Colombia"
                                    lat={10.3932277}
                                    lng={-75.4832311}
                                    imageUrl={cartagena}
                                    cardText="Cartagena"
                                />
                            </Grid>
                        </Grid>
                    )}
                    {searchParams !== '' && (
                        <Box mt={5} mr={4} ml={4}>
                            <ExperiencesList
                                contractExperiences={experiences}
                                useTranslation={useTranslation}
                                experienceService={ExperienceService}
                            />
                        </Box>
                    )}
                    <Grid container justifyContent='center' style={{padding: 10}}>
                        <Grid item>
                            {hasMorePages && experiences.length > 0 ? (
                                <Button
                                    className={classes.loadMoreButton}
                                    onClick={nextPage}>
                                    {translate('pagging.loadMore')}
                                </Button>
                            ) : null} 
                        </Grid>
                    </Grid>
                </Box>
                <Footer />
            </Layout>
        </>
    );
};
export default Landing;
