import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  backgroundGreen: {
    background: 'green',
    color: '#fff',
    borderRadius: 18,
    width: "2.2rem",
    height: "2.2rem"
  },
  borderRed:{
  },
  customPrice: {
    fontSize: 7.5,
  },
  gridPadre: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-daySelected':
      {
        borderStyle: 'solid',
        borderRadius: 18,
        border: '2px solid lightblue',
        color: 'black',
        background: 'lightblue',
      },
  },
  gridDataPicker: {
    marginBottom: '1.2rem',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
  },
  selectedDaySize: {
    fontSize: '0.9rem',
    paddingTop: '0.2rem'
  },
  slotsContainer: {
    maxHeight: '17rem',
    overflowY: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
    },
    '& scrollbar-color': 'darkgrey lightgrey',
    '& scrollbar-width': 'thin',
  },
}));
