import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Typography,
  Box
} from '@material-ui/core';
import { Popover, Button } from 'antd';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { useTranslation } from 'core/contexts/localization';

import { useFilters } from "core/contexts/filter";
import { useStyles } from './styles';
import { TrainRounded } from '@material-ui/icons';

export const RadiusSelect = (props) => {
  const classes = useStyles();

  const { t: translate } = useTranslation();

  const { radius, setRadius } = useFilters();
  const [URLRadius, setURLRadius] = useQueryParam('radius', NumberParam);

  const [radiusSlider, setRadiusSlider] = useState(URLRadius ?? radius)

  const [open, setOpen] = useState(false);

  const budgetMarks = [
    {
      value: 10,
      label: '10 km',
    },
    {
      value: 100,
      label: '100 km',
    },
  ];

  function valuetext(value) {
    return `${value} km`;
  }

  const handleChange = (event, value) => {
    setRadiusSlider(value);
  };


  const hide = (open) => {
    setOpen(!open);
  };

  const handleVisibleChange = (open) => {
    setOpen(open);
  };

  return (
    <>
      <Popover
        placement="bottomLeft"
        content={
          <>
            <FormControl
              variant="outlined"
              className={classes.formControl}
            >
              <Box className={`${classes.containerRadius}  ${classes.root}`} >
                <Slider
                  value={radiusSlider}
                  onChange={handleChange}
                  defaultValue={10}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-always"
                  min={10}
                  step={1}
                  max={100}
                  marks={budgetMarks}
                  valueLabelDisplay="on"
                />
              </Box>
            </FormControl>
            <a onClick={() => {
              setURLRadius(radiusSlider);
              setRadius(radiusSlider);
              setOpen(false);
            }}>{translate("filterBy.apply")}</a>
          </>
        }
        trigger="click"
        visible={open}
        onVisibleChange={handleVisibleChange}>
        <Button className={classes.filterButton}>{translate("filterBy.radius")}</Button>
      </Popover>
    </>
  );
};

RadiusSelect.displayName = 'RadiusSelect';
