import { useEffect, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { InputLabel, MenuItem, Select, FormControl,Box } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';

const Item = ({ language, resources }) => {
  return (
    <img src={resources[language].image} width="16px" height="16px" alt="" />
  );
};

const LanguageInputs = () => {
  const { i18n, t: translate } = useTranslation();
    console.log(i18n);
  const locales = useMemo(
    () => Object.keys(i18n.options.resources),
    [i18n.options.resources]
  );

  const handleChange = (input) => {
    i18n.changeLanguage(input);
  };

  useEffect(() => {
    try {
      window.localStorage.setItem('i18nextLng', i18n.language);
    } catch (e) {
      //ignore
    }
  }, [i18n.language]);

  return (
    <>
      <FormControl variant="outlined">
        <InputLabel id="select-language">
          {translate('layout.language')}
        </InputLabel>
        <Select
          labelId="select-language"
          id="select-language"
          label={translate('layout.language')}
          variant="outlined"
          defaultValue={i18n.language}
          value={i18n.language}
          renderValue={(value) => (
            <Item language={value} resources={i18n.options.resources} />
          )}
          onChange={(e) => handleChange(e.target.value)}>
          {locales.map((value) => {
            return (
              <MenuItem
                key={`language-${value}`}
                style={{ color: 'black' }}
                value={value}>
                <Item language={value} resources={i18n.options.resources} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const LanguageDropDown = () => {
  const handleSubmit = () => {};
  return (
    <Box >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              autoFocus
              name={'LanguageDropDown'}
              component={LanguageInputs}
            />
          </form>
        )}
      />
    </Box>
  );
};

export default LanguageDropDown;
