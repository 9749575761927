import React, { useEffect } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function DatePicker({ label, ...props }) {
  if (
    typeof props.value === 'undefined' ||
    typeof props.onChange !== 'function'
  ) {
    console.error('DatePicker requires both value and onChange props.');
    return null;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable
        ToolbarComponent={() => null}
        label={label}
        placeholder="dd/mm/yyyy"
        format="dd/MM/yyyy"
        inputVariant="filled"
        fullWidth
        variant="static"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
