import { makeStyles } from "@material-ui/core/";
import palette from "core/libs/core-ui/theme/colors-palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  detailsWrapper: {
    maxHeight: "35rem",
    overflowY: "auto",
  },
  detailsContainer: {
    width: "100rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  gridDetailsWrap: {
    display: "flex",
  },
  buttonsOverlay: {
    position: "relative",
    width: "100%",
  },
  imageOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  fullScreenImageButton: {
    position: "absolute",
    zIndex: 150,
    top: "2rem",
    right: 0,
    color: theme.palette.colors.white,
    background:
      "radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 75%)",
  },
  imageButton: {
    position: "absolute",
    zIndex: 150,
    top: "9rem",
    color: theme.palette.colors.white,
    background:
      "radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 75%)",
  },
  imageLeftButton: {
    left: 0,
    "&:active": {
      boxShadow: "none",
    },
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  imageRightButton: {
    right: 0,
    "&:active": {
      boxShadow: "none",
    },
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  mainBox: {
    borderRadius: "15px",
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  containerStyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageStyle: {
    width: "100%",
    height: "20rem",
    objectFit: "cover",
    alignSelf: "start",
  },
  iframeStyle: {
    background: "transparent",
    width: "100%",
    height: "auto",
    maxWidth: "100%",
  },
  experienciceName: {
    display: "inline",
    marginLeft: "0.3rem",
  },
  modusesWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  modusChip: {
    margin: "0.3rem",
    fontSize: "0.7rem",
  },
  checkIcon: {},
  experiencieLocation: {
    display: "flex",
    maxWidth: "20rem",
  },
  experienceDescription: {
    maxWidth: "35rem",
    whiteSpace: "pre-wrap",
  },
  buttonBook: {
    display: "flex",
    background: palette.utriper.lightblue,
    fontWeight: "bold",
    color: "white",
    "&:hover": {
      backgroundColor: palette.utriper.darkblue,
    },
  },
  buttonWrapper: {
    marginTop: "1rem",
    marginBottom: "1rem",
    justifyContent: "center",
    display: "flex",
  },
  locationWrapper: {
    display: "flex",
    alignItems: "center",
  },
  descriptionWrapper: {
    marginLeft: "1rem",
  },
  experiencieSubtitle: {
    maxWidth: "20rem",
    marginBottom: "0.5rem",
  },
  experiencieTitle: {
    marginBottom: "0.5rem",
    maxWidth: "25rem",
  },
  experienceRecycle: {
    paddingLeft: "0rem",
    paddingRight: "0rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
  },
  experienceCancellation: {
    paddingLeft: "0.1rem",
    paddingRight: "0rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
  },
  experienceInclude: {
    paddingLeft: "0rem",
    paddingRight: "0rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
  },
  experienceRecycleWrapper: {
    display: "flex",
  },
  experienceIncludeWrapper: {
    display: "flex",
  },
  experienceCancellationWrapper: {
    padding: "0.4rem",
  },
  experienceCancellationContainer: {
    marginTop: "0.5rem",
  },
  porpersonWrapper: {
    marginLeft: "0.2rem",
    display: "inline",
  },
}));
