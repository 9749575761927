import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Fab, Box } from '@material-ui/core';
import { formatMoney } from 'core/packages/format';

const OrderSummaryItem = (props) => {
  const { name, currency, price, image, quantity } = props.experience;

  return (
    <Grid className="mb-5" container spacing={2} direction='column'>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
        style={{
          width: '16em',
          height: '7em',
          alignSelf: 'center'
        }}>
        <Box
          sx={{
            borderRadius: '7%',
            width: '16em',
            height: '7em',
            backgroundImage: `url("${image}")`,
            backgroundSize: 'cover',
            backgroundPosition: "center"
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8}
        xl={8}
        container
        direction="column"
        style={{alignSelf: 'center'}}
        >
        <Grid container item>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2>{name}</h2>
          </Grid>
          {/* <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Fab
              size="small"
              onClick={() => props.deleteExperience(props.experience)}>
              <CloseIcon style={{ color: 'white' }} />
            </Fab>
          </Grid> */}
        </Grid>
        <Grid item>
          <h3>
            <strong>{currency} {formatMoney(price)} x {Number(quantity)}</strong>
          </h3>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderSummaryItem;
