import CANCELLATION_POLICIES_JSON from "../schemas/cancellation_policies";

export const CANCELLATION_POLICIES = CANCELLATION_POLICIES_JSON;

const getIdsFromNumber = (number) => {
    const binaryString = number.toString(2);
    const binaryArray = binaryString.split("");
    const binaryArrayReversed = binaryArray.reverse();
    const ids = getIds();
    const idsArray = [];
    binaryArrayReversed.forEach((bit, index) => {
        if (bit === "1") {
            idsArray.push(ids[index]);
        }
    });
    return idsArray;
};

const getIds = () => [...Object.values(CANCELLATION_POLICIES).map(policy => policy.id)];

// autogenerate on load
const cancellation_policies_copy = {}
Object.values(CANCELLATION_POLICIES).forEach(value => {
    cancellation_policies_copy[value.id] = value.xlat;
});

export const cancellation_policies = cancellation_policies_copy;

// FIXME: maybe check that id and language are valid?
// how about merging these two functions?
export const getTranslatedName = (cancellation_policies, id, language) => {
    return [cancellation_policies[id][language].name]
};

// const getTranslatedDescription = (id, language) => this.cancellation_policies[id][language].desc;

export const getTranslatedNameFromNumber = (environmental_policies, number, language) => {
    const ids = getIdsFromNumber(number);
    console.log(ids);
    const names = ids.map(id => getTranslatedName(environmental_policies, id, language));
    return names;
};