import { isEmpty } from 'lodash';
import { fetch, Method } from 'core/api/fetch';
import { parseScheduleToModel,  parseScheduleMonthToModel} from 'core/api/parser/schedule';

const ScheduleRoutes = {
    getByDate: (expId, expDetailId, date, mod, code) => `api/v1.1.0/experience/${expId}/expDetail/${expDetailId}/schedule?day=${date}${mod ? `&mod=${mod}` : ''}${code ? `&code=${code}` : ''}`,
    getByDateMonth: (expId, expDetailId, date, mod, groupSize, code) => `api/v1.1.0/experience/${expId}/expDetail/${expDetailId}/availabilty?day=${date}${mod ? `&mod=${mod}` : ''}&group_size=${groupSize ?? 1}${code ? `&code=${code}` : ''}`,
    getByScheduleId: (expId, expDetailId, scheduleId, groupSize, date, code) => `api/v1.1.0/experience/${expId}/expDetail/${expDetailId}/schedule/${scheduleId}?group_size=${groupSize ?? 1}&day=${date}${code ? `&code=${code}` : ''}`,
};

export const ScheduleService = {
    getByDate: async (expId, expDetailId, date, mod, code) => {
        try {
            const { data } = await fetch(ScheduleRoutes.getByDate(expId, expDetailId, date, mod, code), {
                method: Method.GET,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return isEmpty(data) ? [] : data.map(parseScheduleToModel);
        } catch (err) {
            throw new Error(err);
        }
    },
    getByDateMonth: async (expId, expDetailId, date, mod, groupSize ,code) => {
        try {
            const { data } = await fetch(ScheduleRoutes.getByDateMonth(expId, expDetailId, date, mod, groupSize, code), {
                method: Method.GET,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('data---------',data)
            return isEmpty(data) ? [] : data.map(parseScheduleMonthToModel);
        } catch (err) {
            throw new Error(err);
        }
    },


    getByScheduleId: async (expId, expDetailId, scheduleId, groupSize, date, code) => {
        try {
            let { data } = await fetch(ScheduleRoutes.getByScheduleId(expId, expDetailId, scheduleId, groupSize, date, code), {
                method: Method.GET,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            // data[0] = {...data[0], "slots": [
            //     {
            //         "time": "09:00:00",
            //         "available": true,
            //         "id": 0
            //     },
            //     {
            //         "time": "09:30:00",
            //         "available": false,
            //         "id": 1
            //     },
            //     {
            //         "time": "10:00:00",
            //         "available": true,
            //         "id": 2
            //     },
            //     {
            //         "time": "10:30:00",
            //         "available": true,
            //         "id": 3
            //     },
            //     {
            //         "time": "11:00:00",
            //         "available": true,
            //         "id": 4
            //     },
            //     {
            //         "time": "11:30:00",
            //         "available": false,
            //         "id": 5
            //     },
            //     {
            //         "time": "12:00:00",
            //         "available": true,
            //         "id": 6
            //     },
            //     {
            //         "time": "12:30:00",
            //         "available": true,
            //         "id": 7
            //     },
            //     {
            //         "time": "15:30:00",
            //         "available": true,
            //         "id": 8
            //     },
            //     {
            //         "time": "16:00:00",
            //         "available": false,
            //         "id": 9
            //     }
            // ]}
            // data = [
            //     {
            //         "id": 1,
            //         "experienceDetailId": 1,
            //         "createdOn": "2022-03-04T20:59:33.000Z",
            //         "updatedOn": null,
            //         "daysOfWeek": 6,
            //         "date": "2022-03-05",
            //         "start": "2022-03-05T00:00:00.000Z",
            //         "end": "2022-03-05T02:00:00.000Z",
            //         "startTime": "00:00:00",
            //         "endTime": "02:00:00",
            //         "price": 4,
            //         "currency": "COP",
            //         "repeat": true,
            //         "capacity": 3,
            //         "duration": 2,
            //         "frequency": 1,
            //         "unlimitedCapacity": true,
            //         "unlimitedDuration": false,
            //         "unlimitedFrequency": false,
            //         "slots": [
            //             {
            //                 "time": "09:00:00",
            //                 "available": true,
            //                 "id": 0
            //             },
            //             {
            //                 "time": "09:30:00",
            //                 "available": false,
            //                 "id": 1
            //             },
            //             {
            //                 "time": "10:00:00",
            //                 "available": true,
            //                 "id": 2
            //             },
            //             {
            //                 "time": "10:30:00",
            //                 "available": true,
            //                 "id": 3
            //             },
            //             {
            //                 "time": "11:00:00",
            //                 "available": true,
            //                 "id": 4
            //             },
            //             {
            //                 "time": "11:30:00",
            //                 "available": false,
            //                 "id": 5
            //             },
            //             {
            //                 "time": "12:00:00",
            //                 "available": true,
            //                 "id": 6
            //             },
            //             {
            //                 "time": "12:30:00",
            //                 "available": true,
            //                 "id": 7
            //             },
            //             {
            //                 "time": "15:30:00",
            //                 "available": true,
            //                 "id": 8
            //             },
            //             {
            //                 "time": "16:00:00",
            //                 "available": false,
            //                 "id": 9
            //             }
            //         ]
            //     }
            // ]
            // return JSON.parse(JSON.stringify(data[0]))
            return isEmpty(data) ? [] : parseScheduleToModel(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    }
};
