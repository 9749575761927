import MOODS_JSON from "../schemas/moods";
import cultureMoodImg from "core/libs/files/moods/culture.png";
import oneDayActivitiesMoodImg from "core/libs/files/moods/1dayactivities.png";
import drinkingAndEatingMoodImg from "core/libs/files/moods/drinkingandeating.png";
import interactiveNatureMoodImg from "core/libs/files/moods/InteractiveNature.png";
import localWhisperMoodImg from "core/libs/files/moods/localwhisper.png";
import sportsAndWellnessMoodImg from "core/libs/files/moods/sportsandwellness.png";

export const MOODS = MOODS_JSON

// autogenerate on load
// moods.1.es
const moods_copy = {}
Object.values(MOODS).forEach(value => {
    moods_copy[value.id] = { ...value.xlat, id: value.id, img_src: value.image_source };
});

export const moods = moods_copy;

export const getIds = () => [...Object.values(MOODS).map(modus => modus.id)];

export const isIdPresent = (id, moods) => moods[id] !== undefined;

// FIXME: maybe check that id and language are valid?
// how about merging these two functions?
export const getTranslatedName = (id, language, moods) => {
    console.log("id", id, "language", language, "moods", moods);
    return moods[id][language].name;
};

export const getTranslatedDescription = (id, language, moods) => moods[id][language].desc;

export const getImagesSrcArray = () => {
    const ids = getIds();
    // WARNING: this is a hacky way to get the images src array, it must mantain the order of the ids and so do the images
    const imagesSrcArray = [interactiveNatureMoodImg, sportsAndWellnessMoodImg, cultureMoodImg, oneDayActivitiesMoodImg, drinkingAndEatingMoodImg, localWhisperMoodImg];        
    return imagesSrcArray.map((img, index) => {
        return { id: ids[index], img_src: img };
    });
};