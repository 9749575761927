import React, { useState, useEffect } from 'react';
import AutocompleteMUI from '@material-ui/lab/Autocomplete';
import { FormControl, TextField,  makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    
}));

export const AutocompleteInput = (props) => {
  const {
    label,
    options,
    className,
    getOptionLabels,
    onFieldChange,
    errorMessage,
    meta: { touched },
    initialError,
    disabled,
  } = props;

  const [hasError, setHasError] = useState(initialError);
  const [value, setValue] = useState(undefined);
  const classes = useStyles();

  useEffect(() => setHasError(initialError), [initialError]);

  // TODO: Cuando se module a libs, eliminar style
  return (
    <FormControl className={classes.root} >
      <AutocompleteMUI
      style={{width: '100%', marginTop: '1rem'}}
        limitTags={2}
        options={options}
        id="autocomplete"
        className={className}
        getOptionLabel={getOptionLabels}
        disabled={disabled}
        onChange={(event) => {
          setHasError((prevValue) => {
            const retValue = !props.setValidate(event.target.textContent);
            props.setFormError(retValue);
            return retValue;
          });
          setValue(event.target.textContent);
          onFieldChange(event);
        }}
        onClose={(event) => {
          if (event.target.textContent.length === 0) {
            setHasError(true);
          }
        }}
        renderInput={(params) => (
          <TextField
            error={hasError || (!value && touched)}
            helperText={(hasError || (!value && touched)) && errorMessage}
            variant="outlined"
            {...params}
            label={label}
          />
        )}
      />
    </FormControl>
  );
};
