export const parseScheduleToModel = (scheduleExperience) => ({
  id: scheduleExperience?.id,
  date: scheduleExperience.date,
  start: scheduleExperience?.start,
  end: scheduleExperience.end,
  scheduleDate: scheduleExperience?.date,
  capacity: scheduleExperience?.capacity,
  currency: scheduleExperience.currency,
  price: scheduleExperience.price,
  repeat: scheduleExperience.repeat,
  expDetail: scheduleExperience.expDetail, // no existe
  expDetailId: scheduleExperience.experienceDetailId,
  daysOfWeek: scheduleExperience.daysOfWeek,
  duration: scheduleExperience.duration,
  endTime: scheduleExperience.endTime,
  startTime: scheduleExperience.startTime,
  frequency: scheduleExperience.frequency,
  unlimitedCapacity: scheduleExperience.unlimitedCapacity,
  unlimitedDuration: scheduleExperience.unlimitedDuration,
  unlimitedFrequency: scheduleExperience.unlimitedFrequency,
  slots: scheduleExperience.slots,
});


export const parseScheduleMonthToModel = (response) => ({
  availability: response.availability,
  date: response.date,
  slots: response.slots,
  price: response.price
});
